.header-link {
  text-align: end;
}

.header-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.outer-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 900px) {
  h1 {
    font-size: xx-large;
  }
  h2 {
    font-size: x-large;
  }
}

@media (max-width: 600px) {
  h1 {
    font-size: x-large;
  }
  h2 {
    font-size: larger;
  }
  .header-container {
    padding-top: 0.5rem;
  }
  .header-link {
    padding: 0.5rem 0;
    min-width: 48px;
  }
}

@media (max-width: 425px) {
  h1 {
    font-size: larger;
  }
  h2 {
    font-size: large;
  }
}
