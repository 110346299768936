.snack-card {
  text-align: left;
  max-width: 50%;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid black;
  border-radius: 8px;
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;
}

div.scroll-container {
  overflow: auto;
  white-space: nowrap;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  margin-bottom: 1rem;
}

div.scroll-container::-webkit-scrollbar {
  /* hides scroll bar */
  display: none;
}

div.scroll-container img {
  padding: 0 5px;
  height: 200px;
  object-fit: contain;
}

div.scroll-container img:first-child {
  padding-left: 0;
}

div.scroll-container img:last-child {
  padding-right: 0;
}

a {
  color: black;
}

@media (max-width: 900px) {
  .snack-card {
    max-width: 70%;
  }
}

@media (max-width: 600px) {
  .snack-card {
    max-width: 80%;
  }
}
